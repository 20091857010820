.boddy {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;   
    font-family: sans-serif;
    background-color: #f1f1f1; 
}

.cont {
    width: 1000px;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.cont .card {
    position: relative;
    border-radius: 10px;
}

.cont .card .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: 0.7s;
    z-index: 1;
}

.cont .card:nth-child(1) .icon {
    background: #e07768;
}

.cont .card:nth-child(2) .icon {
    background: #6eadd4;
}

.cont .card:nth-child(3) .icon {
    background: #4aada9;
}


.cont .card .icon .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    transition: 0.7s;
    color: #fff;
}

i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    transition: 0.7s;
    color: #fff;
}

.cont .card .face {
    width: 300px;
    height: 200px;
    transition: 0.5s;
}

.cont .card .face.face1 {
    position: relative;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
}

.cont .card:hover .face.face1{
    background: #ff0057;
    transform: translateY(0px);
}

.cont .card .face.face1 .content {
    opacity: 1;
    transition: 0.5s;
}

.cont .card:hover .face.face1 .content {
    opacity: 1;
}

.cont .card .face.face1 .content i{
    max-width: 100px;
}

.cont .card .face.face2 {
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0,0,0,0.8);
    transform: translateY(-100px);
}

.cont .card:hover .face.face2{
    transform: translateY(0);
}

.cont .card .face.face2 .content p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #414141;
}

.cont .card .face.face2 .content h3 {
    margin: 0 0 10px 0;
    padding: 0;
    color: #fff;
    font-size: 24px;
    text-align: center;
    color: #414141;
} 

.cont a {
    text-decoration: none;
    color: #414141;
}