:root {
    --font-family-primary: 'Vollkorn', serif;
    --font-family-secondary: 'PT Sans', sans-serif;
    
    --font-size-title: 32px;
    --font-size-link: 12px;
    --line-height-title: 1.4;
    --font-size-caption: 14px;
    --line-height-caption: 1.2;
    
    --color-text: #222022;
    --color-highlight-primary: #FFEF7E;
    --color-highlight-secondary: #B7F9E9;
    --border-radius-primary: 32px; 


  }
  
  .boody {
    margin-top: 3px;
    margin-left: 10%;
    display:flex;
    justify-content: center;
    align-items: center;
    // flex-direction: block;
    width: 35vw;
    height: 60vh;
    padding: 0;
    float: left;
    // margin: 0;
  }
  
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  @mixin mediaBig {
    @media (min-width: 576px) {
      @content;
    }
  }
  
  @include mediaBig {
    :root {
      --font-size-title: 40px;
    }  
  }
  
  .card {
    // max-width: 700px;
    // min-width: 700px;
    // min-height: 350px;
    // max-height: 350px;
    // width: 75vh;
    // height: 40vh;
    max-width: 100vh;
    max-height: 50vh;
    border-radius: var(--border-radius-primary);
    box-shadow: 24px 24px 80px rgba(0,0,0,0.1);
    padding: 20px 20px 28px 20px;
    box-sizing: border-box;
    margin: 20px;
    display: flex;
    flex-direction: column;
    
    @include mediaBig {
      flex-direction: row;
      align-items: center;

      //TODO Check this
      margin: 40px;
      padding: 32px;
    }
  }
  
  .card__image {
    width: 100%;
    max-height: 300px;
    border-radius: var(--border-radius-primary);
    object-fit: cover;
    margin-bottom: 18px;
    
    @include mediaBig {

      //TODO: change this 200px 200px

      width: 25%;
      max-height: none;
      min-height: 25%;
      margin-bottom: 0;
    }
  }




  .card__secImage {
    width: 100%;
    max-height: 300px;
    border-radius: var(--border-radius-primary);
    object-fit: cover;
    margin-bottom: 18px;
    
    @include mediaBig {

      //TODO: change this 200px 200px

      width: 10%;
      max-height: none;
      min-height: 10%;
      margin-left: 70%;
      margin-bottom: 0;
    }
  }
  
  .card__content {
    @include mediaBig {
      width: 80%;
      padding-left: 40px;
    }
  }
  
  .card__date {
    display: block;
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-caption);
    line-height: var(--line-height-caption);
    text-transform: uppercase;
    color: var(--color-text);
    margin-bottom: 6px;
    
    @include mediaBig {
      margin-bottom: 8px;
    }
  }
  
  .card__title {
    font-family: var(--font-family-primary);
    font-size: 4vh;
    line-height: var(--line-height-title);
    color: var(--color-text);
    text-decoration: none;
    box-decoration-break: clone;
    background-image: linear-gradient(90deg, var(--color-highlight-primary), var(--color-highlight-secondary));
    background-size: 100% 42%;
    background-repeat: no-repeat;
    background-position: 0 85%;
    padding: 0 4px;
    margin-left: -4px;
  }

  .card__description {
    font-family: var(--font-family-primary);
    font-size: 2.5vh;
    line-height: var(--line-height-title);
    color: var(--color-text);
    text-decoration: none;
    box-decoration-break: clone;
    // background-image: linear-gradient(90deg, var(--color-highlight-primary), var(--color-highlight-secondary));
    // background-size: 100% 42%;
    // background-repeat: no-repeat;
    // background-position: 0 85%;
    padding: 0 4px;
    margin-left: -4px;
  }

  .card__git{
    font-family: var(--font-family-primary);
    font-size: 2vh;
    text-decoration: none;
    line-height: var(--line-height-title);
    color: var(--color-text);
    text-decoration: none;
    box-decoration-break: clone;
    padding: 0 4px;
    margin-left: 60%;
  }

  .card__tech_stack{
    font-family: var(--font-family-primary);
    font-size: 16px;
    line-height: var(--line-height-title);
    color: var(--color-text);
    text-decoration: none;
    padding: 0 4px;
    margin-left: -4px;
  }