


.shadow-button-set {
  margin: 0;
  display: flex;
  flex-direction:row;
  align-items: flex-start;
  place-content: center;
  gap: 1.5vmin;
  
  & > li {
    display: inline-flex;
    /* background of offset */
    background: black;
    
    &:is(:hover, :focus-within) > button:not(:active) {
      --distance: -10px;
    }
  }
  
  & button {
    appearance: none;
    outline: none;
    font-size: 3vmin;
    border: 3px solid black;
    background: white;
    
    --distance: 0;
    transform: translateX(var(--distance)) translateY(var(--distance));
    
    @media (prefers-reduced-motion: no-preference) {
      will-change: transform;
      transition: transform .3s ease;
    }
  }
}

.header {
    a{
        text-decoration: none;
        color:rgb(0, 0, 0);
    }
  min-block-size: 50px;
  font-family: system-ui, sans-serif;
  margin-top: 50px;

  place-content: top;
}