@use postcss-nested;



.shadow-button {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5vmin;
  
  & > li {
    display: inline-flex;
    background: black;
    
    &:is(:hover, :focus-within) > button:not(:active) {
      --distance: -10px;
    }
  }
  
  & button {
    appearance: none;
    outline: none;
    
    font-size: 5vmin;
    border: 3px solid black;
    background: white;
    
    --distance: 0;
    transform: translateX(var(--distance)) translateY(var(--distance));
    
    @media (prefers-reduced-motion: no-preference) {
      will-change: transform;
      transition: transform .2s ease ;
    }
  }
}



.wrapper {

  height: 100px;
  margin-top: 15%;
  display: grid;
  place-items: center;
}

.typing-demo {
  width: 17ch;
  animation: typing 2s steps(17), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 10px solid;
  font-family: monospace;
  font-size: 3em;
}


.typing-demo-second {
  margin-top: 3%;
  width: 66ch;
  opacity:0;
  animation: typing-second 3s steps(66), blink .5s step-end infinite alternate;
  animation-delay:3000ms;
  white-space: nowrap;
  animation-fill-mode: forwards;
  overflow: hidden;
  border-right: 10px solid;
  font-family: monospace;
  font-size: 3em;
}




@keyframes typing {
  from {
    width: 0;
    /* opacity: 0; */
  }
}

@keyframes typing-second {
  0% { width: 0;opacity:1; }
  66% { opacity:1; }
  100% { opacity:1; }
}
    
@keyframes blink {
  50% {
    border-color: transparent;
  }
}


.landing-body {

  a{
    text-decoration: none;
    color:black;
}
  min-block-size: 100%;
  font-family: system-ui, sans-serif;
  margin-top: 14%;
  display:grid;
  place-content:center;
}