


/* .education{
    margin-top:5%;
    margin-left: 20%;
   

    width:100ch;
    font: 24px/2 sans-serif;
    
} */

/* CSS styling for the education section */
body{
  background-color: #f1f1f1;
  
  
}
.timeline{
  margin-left: 5%;
  font-family: Arial, sans-serif;
  margin-top: 5%;
  float: left;
}

.skills{
  margin: 1%;
  margin-top: 5%;
  /* optional can be changed later */
  float: left;
}

.lang{
  margin:10%;
  margin-left: 10px;
  margin-top:5%;
  float:right;
  max-width: 350px;
}

.container{
  max-width: 100%;
  max-height: 100%;
}

.courses{
  float: right;
  margin-right: 12.5%;
  max-width: 400px;
}
